<template>
  <div class="container full-height center-content">
    <transition name="fade">
      <div v-show="correct" class="result-overlay">
        <i ref="confetti-place" class="fas fa-check"></i>
        <h2 class="title is-3">Correct</h2>
      </div>
    </transition>
    <testcard :shaking="shaking">
      <div slot="card-content">
        <div class="text has-text-centered">
          <h5 class="title is-6">{{ question.text }}</h5>
        </div>
        <div class="question">
          <vue-mathjax class="title is-4" :formula="question.question"></vue-mathjax>
        </div>
        <div class="answers">
          <div class="columns">
            <div v-for="(solution, index) in question.solution" :key="solution" class="column">
              <div class="field is-horizontal">
                <div v-if="question.prefix" class="field-label is-large">
                  <label class="label title is-5">{{ question.prefix }} =</label>
                </div>
                <b-input @keydown.enter.exact.prevent.native="answer()" :ref="'answers' + index" v-model="answers[index]"></b-input>
              </div>
            </div>
          </div>
          <small>
            <strong>{{ mathquestions }}</strong> vragen tegoed
          </small>
        </div>
      </div>
      <footer slot="card-footer" class="card-footer">
        <div class="card-footer-item">
          <b-button v-if="question.steps" @click="explanation()" type="is-dark">Uitwerkingen</b-button>
        </div>
        <div class="card-footer-item right">
          <div class="buttons">
            <b-button @click="endTest()" class="stop" type="is-text">Stoppen</b-button>
            <b-button @click="answer()" type="is-primary">Controleer</b-button>
          </div>
        </div>
      </footer>
    </testcard>
    <div class="columns stats is-mobile">
      <div class="column is-one-half has-text-centered">
        <h2 class="title is-3">{{ stats.correct }}</h2>Goed
      </div>
      <div class="column is-one-half has-text-centered">
        <h2 class="title is-3">{{ stats.mistakes }}</h2>Fout
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service"
import router from "@/router"
import Image from "@/components/Image"
import Explanation from "@/components/Explanation"
import { confetti } from "dom-confetti"
import { EventBus } from "@/common/event-bus"
import Feedback from "@/components/Feedback"

export default {
  name: "test",
  props: ["year", "level", "topic"],
  components: {
    limg: Image,
  },
  data() {
    return {
      mathquestions: 5,
      question: {
        text: "",
        question: "",
        steps: null,
        prefix: "",
        solution: [],
      },
      answers: [],
      shaking: false,
      correct: false,
      stats: {
        mistakes: 0,
        correct: 0,
      },
    }
  },
  mounted() {
    this.getRemainingQuestions()
    this.getQuestion()
  },
  methods: {
    async getRemainingQuestions() {
      let response = await ApiService.get(`user/mathquestions`)
      this.mathquestions = response.data
      if (this.mathquestions < 1) {
        return this.endTestPremium()
      }
    },
    async getQuestion() {
      //Empty current data
      this.answers = []
      this.question = {}

      //Get a new question and parse it correctly
      let response = await ApiService.get(`math/${this.$props.year}/${encodeURIComponent(this.$props.level)}/${this.topic}`)

      let question = response.data

      //Check if question exists
      if (question.length == 0) {
        return false
      }

      this.question = question
      this.question.question = "$$" + question.question + "$$" //Format it as math using mathjax
      this.question.steps = question.steps //Convert to json object
      this.question.solution = JSON.parse(question.solution) //Convert to json object
      this.question.text = this.question.text

      console.log(this.question.question)
    },
    questionimage(filename) {
      return ApiService.getBase() + "/math/image/" + filename
    },
    async answer() {
      const prefix = this.question.prefix ? this.question.prefix + "=" : ""

      const answers = this.answers.map((answer) => {
        return prefix + answer
      })

      const wrongAnswers = _.difference(answers, this.question.solution)

      for (let i = 0; i < answers.length; i++) {
        if (wrongAnswers.includes(answers[i])) {
          this.$refs["answers" + i][0].$el.classList.add("wronganswer")

          this.shaking = true

          //Incorrect, shake card
          setTimeout(() => {
            this.shaking = false
          }, 1000)
        } else {
          this.$refs["answers" + i][0].$el.classList.remove("wronganswer")
          this.$refs["answers" + i][0].$el.classList.add("correctanswer")
        }
      }

      //If correct, move to next question
      if (wrongAnswers.length == 0) {
        this.mathquestions--
        await ApiService.post(`math/usequestion`)

        if (this.mathquestions < 1) {
          return this.endTestPremium()
        }

        this.stats.correct++

        this.correct = true
        setTimeout(() => {
          confetti(this.$refs["confetti-place"])
        }, 500)

        setTimeout(() => {
          this.correct = false
        }, 2000)
        await this.getQuestion()
      } else {
        this.stats.mistakes++
      }
    },

    explanation() {
      this.$buefy.modal.open({
        parent: this,
        component: Explanation,
        trapFocus: true,
        props: {
          src: ApiService.getBase() + "/math/image/" + this.question.steps,
        },
      })
    },
    endTestPremium() {
      this.$router.push("/dashboard")

      return EventBus.$emit("showPremium")
    },
    endTest() {
      this.$buefy.modal.open({
        parent: this,
        component: Feedback,
      })

      return this.$router.push("/dashboard")
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 50%;
  transition: all 0.5s;
}
.stats {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
.result-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  font-size: 70px;
  color: #9bc53d;
  h2 {
    padding-top: 10px;
    color: #9bc53d;
  }
}
.text {
  margin-bottom: 10px;
}
.answers {
  padding-top: 50px;
  ::v-deep .input {
    background-color: #f0f0f0;
  }
}
.answer-text {
  color: #ccc;
  margin-bottom: 20px;
}
.field {
  justify-content: center;
  .field-label {
    flex-grow: 0;
    min-width: 50px;
    margin-right: 10px;
    letter-spacing: 4px;
  }
  .control {
    max-width: 100px;
  }
}
::v-deep .modal .modal-content {
  text-align: center;
}

::v-deep .wronganswer {
  .input {
    border: 2px solid #f14668 !important;
  }
}
::v-deep .correctanswer {
  .input {
    border: 2px solid #9bc53d !important;
  }
}

.stats {
  width: 50%;
  margin: 0 auto;
}

.stop:hover {
  color: #ff0000;
}
</style>
