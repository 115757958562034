<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body">
      <h2 class="title is-2 has-text-centered">Wat vond je van het wiskunde overhoren?</h2>
      <b-field>
        <b-input v-model="message" type="textarea"></b-input>
      </b-field>
      <b-button @click="fb()" type="is-primary">Versturen</b-button>
    </section>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      message: ''
    }
  },
  methods: {
    async fb() {
      let response = await ApiService.post("suggestions", {
        username: this.$store.state.user.username,
        content: this.message
      }, true, false)
      this.$buefy.toast.open({
        message: "Bedankt voor je feedback!",
        type: "is-success"
      })
      return this.close()
    },
    close() {
      return this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-card-body {
  border-radius: 10px;
}
</style>