<template>
  <div class="image-holder">
    <vue-load-image>
      <img slot="image" :src="src" />
      <b-skeleton slot="preloader" height="40px" width="40%"></b-skeleton>
      <div slot="error">Er ging iets fout...</div>
    </vue-load-image>
  </div>
</template>

<script>
import VueLoadImage from 'vue-load-image'

export default {
  props: ["src"],
  components: {
    'vue-load-image': VueLoadImage
  },

}
</script>
<style scoped lang="scss">
.image-holder {
  margin: 10px 0;
}
</style>