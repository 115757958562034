<template>
  <img :src="src" />
</template>
<script>
export default {
  props: ["src"]
}
</script>
<style lang="scss">
.modal .modal-content {
  text-align: center;
}
</style>